<template>
  <Layout ref="main">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card" id="card_section">
          <div class="card-title p-3 py-2 border-bottom">
            <div class="d-lg-flex align-items-center">
              <div class="me-2 d-inline-block">
                <i class="uil uil-money-withdrawal display-6 text-primary"></i>
              </div>
              <div class="mb-2 mb-lg-0 d-inline-block">
                <h4 class="fw-medium font-size-20 text-dark mb-0">
                  {{ title }}
                </h4>
              </div>

              <div class="ms-auto" v-if="account_type !== 'merchant' && create_modify_permission">
                <!--  @click="$refs.modalFunc.showModal()" -->
                <button class="btn btn-outline-info fw-medium" @click="$refs.modalFunc.showModal()">
                  <i class="uil uil-plus font-size-16 me-1"></i> Create Withdrawal Request
                </button>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-12 m-0 p-0"></div>
              <div class="col-12 col-lg-12 col-xl-4 mb-3">
                <label class="form-label mb-1">Reseller Contract</label>
                <multiselect
                  ref="select"
                  v-model="selectedResellerContract"
                  :options="resellerContract"
                  label="account_name"
                  placeholder="Select"
                  :close-on-select="true"
                  :allow-empty="false"
                  deselect-label=""
                  :show-on-select="true"
                  :disabled="disabled"
                  @open="isOpen = true"
                  @close="isOpen = false"
                  @input="handlePageChange(1)"
                >
                <template slot="singleLabel" slot-scope="props">
                  <span v-if="props.option.contract_id!==''">
                    <span v-if="props.option.contract_status=='unavailable'" class="text-warning me-1">&#9679;</span>
                    <span v-else-if="props.option.contract_status=='available'" class="text-success me-1">&#9679;</span>
                    <span class="option__title"> {{ props.option.account_name }}: {{ props.option.contract_name }} 
                    </span>
                  </span>
                  <span v-else>{{ props.option.contract_name }}  </span>
                </template>
                <template slot="option" slot-scope="props">
                  <span v-if="props.option.contract_id!==''">
                    <span v-if="props.option.contract_status=='unavailable'" class="text-warning me-1">&#9679;</span>
                    <span v-else-if="props.option.contract_status=='available'" class="text-success me-1">&#9679;</span>
                    <span class="option__title">  {{ props.option.account_name }}: {{ props.option.contract_name }} 
                    </span>
                  </span>
                  <span v-else>{{ props.option.contract_name }}</span>
                </template>
                <span slot="noResult">Oops! No Contract found. </span>
                </multiselect>
              </div>
              <div class="col-12 col-md col-xl-4 mb-0 d-none">
                <label class="form-label mb-1">Date Range</label>
                <date-picker
                  v-model="date_range"
                  type="date"
                  range
                  value-type="format"
                  format="YYYY-MM-DD"
                  placeholder="Select Date Range"
                  :disabled="disable_date"
                  :disabled-date="notBeforeToday"
                  :clearable="false"
                  :editable="false"
                  :shortcuts="shortcuts"
                  class="mb-3"
                ></date-picker>
                <!-- 
                  @change="DateChanged()" -->
              </div>

              <div class="col-12 col-md-auto col-xl-auto">
                <label class="form-label mb-1 d-md-block d-none">&nbsp;</label>
                <div class="row">
                  <div class="col-6 pe-1 col-auto d-grid">
                    <button
                      class="btn btn-light mb-3 text-nowrap"
                      type="button"
                      :disabled="disabled"
                      @click="clear()"
                    >
                      <i class="uil-redo me-1"></i> Clear
                    </button>
                  </div>
                  <div class="col-6 ps-1 col-auto d-grid">
                    <button
                      class="btn btn-info text-white mb-3 text-nowrap"
                      type="button"
                      :disabled="disabled"
                      @click="search()"
                    >
                      <i class="uil-search me-1"></i> Search
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-12 col-xl">
                <div class="float-end">
                  <div class="mb-3 ml-auto d-none">
                    <b-button
                      type="submit"
                      variant="info"
                      @click="exportData()"
                      :disabled="disableExport"
                    >
                      <i class="uil-file-export me-1"></i>
                      Export
                      <div
                        class="spinner-border spinner-border-sm text-white"
                        v-if="loadingButton"
                        role="status"
                      ></div>
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="table-responsive text-nowrap font-size-14 position-relative"
            >
              <table
                id="freeze"
                class="table mt-2 mb-0 align-middle min-width-760"
                :class="{ 'table-hover': returnData.length > 0 }"
              >
                <thead class="text-uppercase">
                  <tr>
                    <th>Withdrawal Id<br>Initiator</th>
                    <th>Created Date<br>Updated Date</th>
                    <th>Reseller Code<br>Reseller Name</th>
                    <th>Reseller Contract<br>Contract Currency</th>
                    <th>Receiving Bank/Crypto<br>Withdrawal currency</th>
                    <th>Account Name/Network<br>Account No./Wallet Address</th>
                    <th>Withdrawal Amount<br>Amount After Charges</th>
                    <th>Total Charges<br>Rate and Extra Charges</th>
                    <th>Status</th>
                    <th class="text-center" v-if="account_type=='admin' && create_modify_permission">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!returnData.length && !pageLoading">
                    <td :colspan="(account_type == 'admin' && create_modify_permission) ? 10 : 9" height="300" class="text-center text-muted">
                      <i class="uil uil-money-withdrawal me-1"></i> No Data
                      Available
                    </td>
                  </tr>
                  <tr v-if="pageLoading">
                    <td :colspan="(account_type == 'admin' && create_modify_permission) ? 10 : 9" height="400" class="text-center text-muted">
                      <div
                        class="spinner-border text-secondary my-2"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                      <br />
                      <div>Loading...</div>
                    </td>
                  </tr>

                  <tr v-for="(value, index) in returnData" :key="index">
                    <td>
                      {{ value.withdrawal_id }}<br>
                      <span class="badge bg-soft-purple font-size-13 mt-1 text-capitalize">  {{ value.initiator }} </span>
                    </td>
                    <td>
                      {{ value.created_at }}<br>
                      {{ value.updated_date || '-'}}
                    </td>
                    <td>
                      {{ value.reseller_code }}<br>
                      {{ value.reseller_name }}
                    </td>
                    <td>
                      {{ value.reseller_contract_name }}<br>
                      {{ value.reseller_contract_currency }}
                    </td>
                    <td>
                      {{ value.receiver_bank }}<br>
                      {{ value.settlement_currency }}
                    </td>
                    <td> 
                      {{ value.receiver_bank_acc_name }}<br>
                      {{ value.receiver_bank_acc }}
                    </td>
                    <td>
                      {{ value.reseller_contract_currency }} {{ convertCurrencyFormat(value.withdraw_amount,true)}} <br>
                      {{ value.reseller_contract_currency }} {{ convertCurrencyFormat(value.total_withdrawal_after_charge,true)}} 
                    </td>
                    <td> 
                      {{ value.reseller_contract_currency }}  {{  convertCurrencyFormat(value.total_charges, true) }}
                      <br>
                      {{value.reseller_contract_rate}}% +
                      {{ value.reseller_contract_currency }} {{value.reseller_contract_extra_charges}}
                    </td>
                    <td>
                      <span class="badge bg-soft-info font-size-14" v-if="value.status =='reserved'">Reserved
                      </span> 
                      <span class="badge bg-soft-warning font-size-14" v-else-if="value.status =='pending'">Pending</span>
                    </td>
                    
                    <td v-if="account_type=='admin' && create_modify_permission" class="text-center">

                      <button type="button" v-if="value.status == 'pending'" class="btn btn-outline-info px-2 py-1 font-size-15 rounded" :disabled="pageLoading" @click="updateWithdrawal(value)"><i class="uil uil-upload"></i> </button>
                      <button type="button" v-else-if="value.status == 'reserved' && value.processed_by == accessUsername" class="btn btn-outline-info px-2 py-1 font-size-15 rounded" :disabled="pageLoading" @click="updateWithdrawal(value)"><i class="uil uil-upload"></i> </button>
                      <span v-else-if="value.processed_by !== accessUsername" class="text-start text-muted">This action is only<br>allowed for {{value.processed_by }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row" v-if="returnData.length && !pageLoading">
              <div class="col mt-3">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      hide-ellipsis
                      @change="handlePageChange"
                      v-model="currentPage"
                      :per-page="perPage"
                      :total-rows="totalData"
                    >
                    </b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Lottie
      :path="'/animate/loading_purple.json'"
      :title="'Loading'"
      :info="'Please wait...'"
      :show="showLottie"
      ref="lottieFunc"
    />
    <Common ref="commonFunc" />
    <createWithdrawal ref="modalFunc" :data="{'accessUsername':accessUsername, 'assessToken':accessToken}" @callParentFunction="handlePageChange(currentPage)"/>
    <updateWithdrawal ref="modalFunc2" :data="{'accessUsername':accessUsername, 'assessToken':accessToken, 'updateData':updateData}" @callParentFunction="handlePageChange(currentPage)" />
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Lottie from "@/components/lottieView";
import Common from "@/components/common";
import axios from "axios";
import Swal from "sweetalert2";
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
import createWithdrawal from "@/views/pages/app/modal/createWithdrawal";
import updateWithdrawal from "@/views/pages/app/modal/updateWithdrawal";
/**
 * Page
 */
const PageTitle = "Pending Withdrawals";

const CancelToken = axios.CancelToken;
let cancel;

const usersDefault = {
  processedById: -1,
  processedBy: "All Users",
};
const BankDefault = {
  id: -1,
  name: "All Banks",
  countryId: 0,
  bankCode: "",
  websiteURL: "",
  isActive: 1,
  category: "",
  isSuspend: 0,
  attachment: null,
  tranCount: 0,
  successTranCount: 0,
  tranPercentage: 0,
  createdAt: "",
};
const CountryDefault = {
  id: -1,
  name: "All Countries",
  currencyId: 0,
  currencyName: "",
  timeZone: [],
};
const AllAccountDefault =
{
  "id": "",
  "account_username": "",
  "account_db_code": "all",
  "account_name": "All Resellers",
  "account_type": "",
  "up_reseller_count": 0,
  "reseller_parent": [],
  "reseller_level": "",
  "reseller_code": "",
  "merchant_count": 0
}
const resellerContractDefault ={
  "status": "0",
  "payin_rate": 0,
  "contract_id": "",
  "payout_rate": 0,
  "total_payin": 0,
  "ewallet_rate": 0,
  "total_payout": 0,
  "contract_name": "All Contracts",
  "contract_total": 0,
  "contract_status": "",
  "settlement_rate": 0,
  "contract_balance": 0,
  "total_settlement": 0,
  "contract_currency": "",
  "payin_extra_charges": 0,
  "payout_extra_charges": 0,
  "ewallet_extra_charges": 0,
  "settlement_extra_charges": 0,
  "contract_list_length_total": 0,
  "account_name": "",
  "reseller_id": "",
  "reseller_name": "",
  "reseller_code": "",
  "reseller_db_code": "",
  "reseller_level": "",
  "reseller_parent": []
}
const ResellerDefault =
{
  "account_db_code": "all",
  "account_name": "All Resellers",
  "account_type": "",
  "up_reseller_count": 0,
  "reseller_parent": [],
  "reseller_level": "",
  "reseller_code": "",
  "merchant_count": 0
}
export default {
  components: {
    Layout,
    PageHeader,
    Lottie,
    Common,
    DatePicker,
    Multiselect,
    createWithdrawal,
    updateWithdrawal
  },
  page() {
    return {
      title: PageTitle,
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    };
  },
  data() {
    return {
      accessToken: "",
      accessEmail: "",
      accessPhone: "",
      accessUsername: "",
      account_type:"",
      showLottie: false,
      title: "",
      items: [
        {
          text: appConfig.pageTitle,
          to: "/",
        },
        {
          text: "",
          active: true,
        },
      ],
      formData: {
        apiID: "YOUR_API_ID",
        apiHash: "YOUR_API_HASH",
      },
      submitted: false,
      loading: false,
      pageLoading: false,
      buttonLoading: [false, false, false],
      currentPage: 1,
      currencySymbol: "₹",
      selectedReseller: ResellerDefault,
      reseller:[ResellerDefault],
      selectedResellerContract: resellerContractDefault,
      resellerContract: [resellerContractDefault],
      selectedCountry: CountryDefault,
      countryList: [CountryDefault],
      selectedBanks: BankDefault,
      banksList: [BankDefault],
      selectedUsers: usersDefault,
      usersList: [usersDefault],
      seletedMethod: "",
      selectedStatus: "",
      searchWithdrawalId: "",
      searchMerchantItemId: "",
      filterType: "created_date",
      searchAmount: "",
      perPage: 20,
      totalData: 0,
      returnData: [],
      returnPaidTotal: 0,
      returnPayoutProcessed: 0,
      export_data: [],
      search_value: "",
      disableExport: false,
      loadingButton: false,
      date_range: null,
      disable_date: false,
      disabled: false,
      shortcuts: [],
      updateData: {},
      currentMerchantInfo:{},
      currentResellerInfo:{},
      create_modify_permission:false
    };
  },
  middleware: "authentication",
  async mounted() {
    this.title = PageTitle;
    this.items[1].text = PageTitle;
    // this.$refs.main.changeVerticalTopBar("",true) *first value is back link and second is want set bg color?
    // this.$refs.main.changeVerticalTopBar("bill",true)
    // this.$refs.main.setShowFooterCert(false)
    // this.$refs.main.setPageTitle('title')
    await this.reload();
    this.accessToken = this.$refs.commonFunc.getToken();
    this.accessUsername = this.$refs.commonFunc.getUsername();
    this.date_range = this.$refs.commonFunc.last30Days();
    this.shortcuts = this.$refs.commonFunc.datePickerShortcut();
    if (this.account_type=='admin'){
      this.getReseller();
    }
    if (this.account_type=='reseller'){
      this.getResellerContract();
    }
    //this.getCountries();
    //this.getUserList();
    await this.handlePageChange(1);
  },
  created() {},
  methods: {
    updateWithdrawal(value){
      this.updateData=value;
      setTimeout(() =>  this.$refs.modalFunc2.showModal(),100)
    },
    convertCurrencyFormat(value, show00) {
      if (show00 == true) {
        return this.$refs.commonFunc.convertCurrencyFormat(value);
      } else {
        // Assuming this.transactionLimit is defined in your component's data
        return this.$refs.commonFunc
          .convertCurrencyFormat(value)
          .replaceAll(".00", "");
      }
    },
    inputNumberOnly(event, parentModel) {
      const numericValue = event.target.value.replace(/[^0-9.]/g, "");
      this[parentModel] = numericValue;
    },
    countryChanged() {
      //this.selectedBanks=BankDefault
      this.handlePageChange(1);
    },
    returnBankList() {
      if (this.selectedCountry.id !== -1) {
        return this.banksList.filter(
          (bank) => bank.countryId === this.selectedCountry.id
        );
      } else {
        return this.banksList;
      }
    },
    getBankList() {
      console.log("getBankList");
      this.$Progress.start();
      this.loading = true;
      this.disabled = true;
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      axios({
        method: "get",
        url: "/api/",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          var resData = response.data;
          console.log(resData.result);
          if (resData.status == 200) {
            this.selectedBanks = BankDefault;
            this.banksList = [this.selectedBanks];
            const isSuspend = [];
            resData.result.forEach((element) => {
              if (element.isSuspend === 1) {
                isSuspend.push(element);
              } else {
                this.banksList.push(element);
              }
            });
            isSuspend.forEach((element) => {
              this.banksList.push(element);
            });
          } else if (resData.status == 440) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
          }
          this.loading = false;
          this.disabled = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.loading = false;
          this.disabled = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: `${error}.`,
            confirmButtonColor: "#222",
            confirmButtonText: this.$t("siteLang.Done"),
          });
        });
    },
    getUserList() {
      console.log("getUserList");
      this.$Progress.start();
      this.loading = true;
      this.disabled = true;
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      axios({
        method: "post",
        url: appConfig.APIHostAdmin + 'controller/admin/getAllUserList',
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          var resData = response.data;
          if (resData.status == 200) {
            this.selectedUsers = usersDefault;
            this.usersList = [this.selectedUsers];
            resData.data.forEach((element) => {
              this.usersList.push(element);
            });
          } else if (resData.status == 440) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
          }
          this.loading = false;
          this.disabled = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.loading = false;
          this.disabled = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: `${error}.`,
            confirmButtonColor: "#222",
            confirmButtonText: this.$t("siteLang.Done"),
          });
        });
    },
    getCountries() {
      console.log("getCountries");
      this.$Progress.start();
      this.loading = true;
      this.disabled = true;
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      axios({
        method: "get",
        url: "/api/",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          var resData = response.data;
          if (resData.status == 200) {
            (this.selectedCountry = CountryDefault),
              (this.countryList = [this.selectedCountry]);
            resData.result.forEach((element) => {
              this.countryList.push(element);
            });
          } else if (resData.status == 440) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
          }
          this.loading = false;
          this.disabled = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.loading = false;
          this.disabled = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: `${error}.`,
            confirmButtonColor: "#222",
            confirmButtonText: this.$t("siteLang.Done"),
          });
        });
    },
    getReseller() {
      console.log("getReseller")
      this.$Progress.start();
      this.loading = true
      this.disabled = true
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append("page", "1");
      bodyFormData.append("limit", "999999");
      bodyFormData.append("resellerId", (this.account_type==='admin') ? "" : "all", );
      bodyFormData.append("searchReseller", "")
      axios({
        method: "post",
        url: appConfig.APIHostAdmin + 'controller/admin/getAllResellerList',
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data"
        },
      }).then((response) => {
        var resData = response.data;
        if (resData.status == 200) {
            this.reseller = [ResellerDefault]
            this.selectedReseller = ResellerDefault
            if (this.account_type=='admin'){
              this.reseller=[AllAccountDefault]
            }
            if (this.account_type=='reseller'){
              this.reseller=[resData.current_account]
              this.selectedReseller = this.reseller[0]
              this.currentAccount = resData.current_account
            }
            resData.data.forEach((element) => {
              this.reseller.push(element);
            });
            this.getResellerContract()

        } else if (resData.status == 440) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: `${resData.message}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
          localStorage.clear();
          this.$router.push({
            path: "/login",
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: `${resData.message}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
          this.loading = false;
          this.disabled = false
        }
        this.$Progress.finish();
      }).catch((error) => {
        this.loading = false;
        this.disabled = false
        this.$Progress.finish();
        //Swal.fire("Error", error, "error");
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          html: `${error}.`,
          confirmButtonColor: '#222',
          confirmButtonText: this.$t('siteLang.Done'),
        })
      });
    },
    getResellerContract() {
      console.log("getResellerContract");
      this.$Progress.start();
      this.loading = true;
      this.disabled = true;
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append("page", "1");
      bodyFormData.append("limit", "9999999");
      bodyFormData.append("reseller",(this.account_type==='reseller') ? this.currentResellerInfo.account_db_code: this.selectedReseller.account_db_code);
      axios({
        method: "post",
        url: appConfig.APIHostAdmin + 'controller/admin/getContractListForReseller',
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          var resData = response.data;
          if (resData.status == 200) {
               this.selectedResellerContract = resellerContractDefault
              this.resellerContract = [this.selectedResellerContract];
              resData.data.forEach((element) => {
                this.resellerContract.push(element);
              });
          } else if (resData.status == 440) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
          }
          this.loading = false;
          this.disabled = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.loading = false;
          this.disabled = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: `${error}.`,
            confirmButtonColor: "#222",
            confirmButtonText: this.$t("siteLang.Done"),
          });
        });
    },
    notBeforeToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
    DateChanged() {
      console.log("datechange" + this.date_range);
      this.handlePageChange(1);
      this.currentPage = 1;
      this.disable_date = true;
    },
    handlePageChange(current_page) {
      this.pageNum = current_page;
      this.currentPage = current_page;
      this.getData(this.pageNum, this.search_value);
      //this.exportData(1)
    },

    search() {
      this.handlePageChange(1);
      this.currentPage = 1;
    },
    clear() {
      this.selectedCountry = CountryDefault;
      this.selectedReseller = ResellerDefault;
      this.selectedResellerContract = resellerContractDefault;
      this.selectedBanks = BankDefault;
      this.seletedMethod = "";
      this.selectedStatus = "";
      this.searchWithdrawalId = "";
      this.searchMerchantItemId = "";
      this.filterType = "created_date";
      this.searchAmount = "";
      this.date_range = this.$refs.commonFunc.last30Days();

      this.search_value = "";
      this.handlePageChange(1);
      this.currentPage = 1;
    },

    exportToExcel(Data, name, type) {
      var title = name.replace(/ /g, "_");
      var ws = window.XLSX.utils.json_to_sheet(Data);
      let objectMaxLength = [];
      for (let i = 0; i < Data.length; i++) {
        let value = Object.values(Data[i]);
        for (let j = 0; j < value.length; j++) {
          if (typeof value[j] == "number") {
            objectMaxLength[j] = 5;
          } else {
            objectMaxLength[j] =
              objectMaxLength[j] >= value[j].length
                ? parseInt(objectMaxLength[j])
                : parseInt(value[j].length) + 5;
          }
        }
      }
      var wscols = [];
      objectMaxLength.forEach((value, index) => {
        console.log(value);
        wscols.push({ width: objectMaxLength[index] });
      }),
        (ws["!cols"] = wscols);

      var wb = window.XLSX.utils.book_new();
      window.XLSX.utils.book_append_sheet(wb, ws, name);
      window.XLSX.writeFile(wb, title + "." + (type || "xlsx"));
    },
    async getData(pages, keyword) {
      if (cancel !== undefined) cancel();
      this.$Progress.start();
      this.pageLoading = true;
      this.disabled = true;
      this.disable_date = true;
      this.returnData = [];
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append("page", pages);
      bodyFormData.append("limit", this.perPage);
      // bodyFormData.append("dateStart", this.date_range[0]);
      // bodyFormData.append("dateEnd", this.date_range[1]);
      bodyFormData.append("dateStart",'');
      bodyFormData.append("dateEnd", '');
      bodyFormData.append("resellerContractId", this.selectedResellerContract.contract_id);
      console.log(
        this.selectedResellerContract.contract_id,
        // this.seletedMethod,
        // this.selectedStatus,
        // this.selectedCountry.id,
        // this.selectedUsers.processedById,
        // this.searchSettlementId,
        // this.searchMerchantItemId,
        // this.searchAmount,
        // this.filterType,
        keyword,
        this.date_range[0],
        this.date_range[1]
      );
      axios({
        method: "post",
        url: appConfig.APIHostAdmin + 'controller/admin/getAllPendingResellerWithdrawalList',
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
        cancelToken: new CancelToken((c) => (cancel = c)),
      })
        .then((response) => {
          var resData = response.data;
          if (resData.status == 200) {
            console.log(resData)
            this.returnData = [];
            this.returnData = resData.data;
            this.totalData = resData.total;
          }
          else if (resData.status == 440){
            Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
              localStorage.clear();
              this.$router.push({
                  path: "/login",
            });
          }
          else {
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
          }
          this.pageLoading = false;
          this.disabled = false;
          this.disable_date = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.pageLoading = false;
          this.disabled = false;
          this.disable_date = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: `${error}.`,
            confirmButtonColor: "#222",
            confirmButtonText: this.$t("siteLang.Done"),
          });
        });
    },
    async exportData() {
      this.$Progress.start();
      this.disableExport = true;
      this.loadingButton = true;
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("phone", this.accessPhone);
      // bodyFormData.append("page", pages);
      // bodyFormData.append("limit", this.perPage);
      axios({
        method: "get",
        url: `https://dummyjson.com/users/search?q=&limit=100&skip=1`,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          this.export_data = [];
          var resData = response.data;
          this.totalData = resData.total;
          var users = resData.users;

          for (var i in users) {
            // Combine date and time
            const depositDateTime = `29 Jun 2023, 12:30:00`;
            // Generate a random deposit amount
            const randomDeposit = Math.floor(Math.random() * 10000) + 1000; // Random amount between 1000 and 11000

            // Calculate merchant fee and nett amount
            const merchantFeePercentage = 2.5 / 100; // 2.5% merchant fee
            const merchantFee = Math.floor(
              randomDeposit * merchantFeePercentage
            );
            const nettAmount = randomDeposit - merchantFee;
            const randomStatus = Math.random() < 0.8 ? "success" : "failed";
            var randomMode = Math.random() < 0.8 ? "UPI" : "IMPS";
            var transaction_id = "";
            if (randomMode == "UPI") {
              transaction_id = "320020673657";
            } else {
              transaction_id = "IMPS/123443211240";
            }

            const listData = {
              id: "ids",
              deposit_date: depositDateTime,
              customer_id: `${users[i].id}`,
              customer_name: `${users[i].firstName} ${users[i].lastName}`,
              deposit_amount: randomDeposit.toString(),
              deposit_mode: randomMode.toString(),
              merchant_fee: merchantFee.toString(),
              nett_amount: nettAmount.toString(),
              transaction_id: transaction_id,
              status: randomStatus,
            };
            this.export_data.splice(i, 0, listData);
          }
          var self = this;
          setTimeout(function () {
            self.disableExport = false;
            self.loadingButton = false;
            self.exportToExcel(self.export_data, "Summary", "xlsx");
          }, 1200);

          // if (resData.status == 200) {
          //   console.log(resData)
          //   this.totalData = 10
          // }
          // else if (resData.status == 440){
          //   Swal.fire(
          //       {
          //       icon: 'error',
          //       title: 'Oops...',
          //       html: `${resData.message}.`,
          //       confirmButtonColor: '#222',
          //       confirmButtonText: this.$t('siteLang.Done'),
          //     })
          //     localStorage.clear();
          //     this.$router.push({
          //         path: "/login",
          //   });
          // }
          // else {
          //     Swal.fire(
          //       {
          //       icon: 'error',
          //       title: 'Oops...',
          //       html: `${resData.message}.`,
          //       confirmButtonColor: '#222',
          //       confirmButtonText: this.$t('siteLang.Done'),
          //     })
          // }
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.disableExport = false;
          this.loadingButton = false;
          this.loading = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: `${error}.`,
            confirmButtonColor: "#222",
            confirmButtonText: this.$t("siteLang.Done"),
          });
        });
    },
    async reload() {
      try {
        await this.$nextTick(); // Wait for the component to be fully mounted

        const commonFunc = this.$refs.commonFunc;
        if (commonFunc && commonFunc.getFullData) {
          const data = await commonFunc.getFullData();
          this.currentMerchantInfo = data.merchant_info;
          this.currentResellerInfo = data.reseller_info;
          this.account_type = data.account_type;
          const permission = data.modules.filter(e => e.name==='withdrawal')
          if (permission) {
            this.create_modify_permission = permission[0].create_modify_permission==0 ? false : true; 
            console.log(this.create_modify_permission)
          } else {
            console.log('Module with name "withdrawal" not found.');
          }
        } else {
          // Handle the case when commonFunc or getFullData is undefined
          // Perform appropriate actions or show a fallback message to the user
        }
      } catch (error) {
        // Handle the error silently without propagating it further
      }
    },
  },
};
</script>
